<template>
  <div style="padding: 1rem;">

    <b-card no-body>
      <b-card-body>

        <!-- <RequestReservations /> -->
        <!-- <b-tabs v-model="tabIndex">
          <b-tab @click="setTab(0, $t('general'))">
            <template #title>
              <feather-icon icon="FileTextIcon" size="18" />
              <span>{{ $t('general')}} </span>
            </template>
          </b-tab>
        </b-tabs> -->
        <Exchanges></Exchanges>
        <TermsModal
          v-if="!!termCondition"
          :termCondition="termCondition"
          :isLoadingTermsConditions="isLoadingTermsConditions"
        />
        <div class="text-center">
          <label v-if="exchangesSubscriptionInfo">{{ $t('subscribed since', {date: formatThisDate(exchangesSubscriptionInfo.createDate)}) }}</label> <br>
          <b-button variant="primary" class="w-25" type="button" @click="openModalExchanges">{{ !!exchangesSubscriptionInfo ? $t('terms and use') : $t('subscribe')}}</b-button>
        </div>
      </b-card-body>
    </b-card>


  </div>
</template>

<script>
import vSelect from 'vue-select'
import { utils } from '@/modules/owners/mixins/utils'
import { mapState, mapActions, mapMutations } from 'vuex'
import { showAlertMessage, formatDate } from '@/helpers/helpers'

import Exchanges from '@/modules/owners/components/exchanges/ExchangesGeneral'
import TermsModal from '@/modules/owners/components/exchanges/TermsModal'

export default {
  mixins:[utils],
  components: {
    vSelect,
    Exchanges,
    TermsModal,
  },
  async created(){
    await this.init()
  },
  data() {
    return {
      tabIndex: 0,
      isLoadingExchangesInfo: false,
      suscriptionInfo: null,
      isLoadingTermsConditions: false,
      termCondition: null
    }
  },
  computed: {
    ...mapState('owners', ['exchangesSubscriptionInfo']),
    ...mapState('auth', ['user','myContracts']),
  },
  methods:{
    ...mapActions('owners', ['checkIfOwnerIsSubscribedToExchanges', 'fetchTermsConditions', 'pushActionsService']),
    ...mapMutations('owners', ['setExchangesSubscriptionInfo']),
    async init(){
      this.isLoadingExchangesInfo = true
      const response = await this.checkIfOwnerIsSubscribedToExchanges({idContract: this.myContracts?.contractSelected?.id, idOwner: this.user.idOwner})
      if (!response?.status) {
        showAlertMessage( this.$t('something failed'), 'InfoIcon', this.$t(`try again`), 'danger', 4000, 'bottom-right')
      }
      this.setExchangesSubscriptionInfo(response?.data || null)
      this.isLoadingExchangesInfo = false
    },
    async setTab(index, infoTab){
      this.tabIndex = index
      await this.pushData('onClick', 'clickTabOption', 'clickTabOption', this.$route.name, infoTab) // push a back del registr
    },
    formatThisDate(date){
      return formatDate({date, language: this.user?.languageName})
    },
    async openModalExchanges(){
      this.isLoadingTermsConditions = true
      this.termCondition = await this.fetchTermsConditions( {idTerm: 24, idLanguage: this.user.languageId, typeTerms: 3}  )
      if (Boolean(this.termCondition?.content)) {
        const button = !!this.exchangesSubscriptionInfo ? 'exchangesViewSubscribe' : 'exchangesViewTerms'
        await this.pushData('onClick', 'openModalExchanges', button, this.$route.name) // push a back del registr
        this.$root.$emit('bv::show::modal', 'modal-exchanges')
      }
      this.isLoadingTermsConditions = false

    }
  },
}
</script>