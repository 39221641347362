<template>
  <b-card>
    <h1 class="text-center">{{ $t('home exchanges') }}</h1>

    <div v-if="langLocale === 'en'" class="w-50 m-auto">
      <p>As a vacation owner, you and your family can enjoy great, affordable vacations year after year. And with an Interval membership, you’ll have access to a variety of benefits that will help you make the most out of your vacation time.*</p>
      <ul>
        <li>Gain access to thousands of resorts in more than 90 countries and territories when you exchange your vacation time for spacious, quality accommodations at some of the world’s most desirable resort destinations! </li>
        <li>Nearly 1.6-million vacation owner families trade through Interval’s worldwide exchange network, giving you more exchange opportunities to enjoy. </li>
        <li>Travel more often, without relinquishing your time, with Getaways. </li>
        <li>Interval's online member publication features exciting vacation ideas, travel inspiration, and tips for maximizing your membership. </li>
        <li>Give the gift of travel by assigning the use of your resort time or Getaway to friends and family by purchasing affordable Guest Certificates. </li>
      </ul>
      <h6>Travel with Ease</h6>
      <ul>
        <li>Interact with other members on Community to give and receive travel advice and tips. </li>
        <li>Book flights, cruises, sightseeing tours, or even rent a car, through Interval Travel®. </li>
        <li>Get up to 60% off hotels - powered by Priceline Partner Solutions™. </li>
        <li>Visit intervalworld.com to do it all in one easy-to-use members-only website. Search the Resort Directory, discover great travel deals, find valuable discounts, purchase theme park tickets in advance, and more! And making an exchange or booking a Getaway couldn’t be easier — just point, click, and get ready to pack! </li>
      </ul>
      <label>*Benefits vary by member's country of residence.</label>
    </div>
    <div v-else class="w-50 m-auto">
      <p>
        Como propietario vacacional, usted y su familia pueden disfrutar de unas vacaciones magníficas y asequibles año tras año. Y con una afiliación a Interval, tendrá acceso a una variedad de beneficios que le ayudarán a aprovechar al máximo su tiempo de vacaciones.* <br>
      </p>
      <ul>
        <li> Obtenga acceso a miles de complejos turísticos en más de 90 países y territorios cuando intercambie su tiempo de vacaciones por alojamientos amplios y de calidad en algunos de los destinos turísticos más deseados del mundo. </li>
        <li> Casi 1,6 millones de familias de propietarios de vacaciones intercambian a través de la red mundial de intercambio de Interval, lo que le brinda más oportunidades de intercambio para disfrutar. </li>
        <li> Viaje más a menudo, sin renunciar a su tiempo, con Getaways. </li>
        <li> La publicación en línea para socios de Interval ofrece interesantes ideas de vacaciones, inspiración para viajar y consejos para aprovechar al máximo su afiliación. </li>
        <li> Regale viajes asignando el uso de su tiempo en el complejo o Getaway a amigos y familiares mediante la compra de asequibles Certificados de Invitado. </li>
      </ul>

      <h6>Viaje con facilidad</h6>

      <ul>
        <li> Interactúe con otros miembros en la Comunidad para dar y recibir consejos y sugerencias sobre viajes.</li>
        <li> Reserve vuelos, cruceros, visitas turísticas o incluso alquile un coche a través de Interval Travel®.</li>
        <li> Consigue hasta un 60% de descuento en hoteles - impulsado por Priceline Partner Solutions™.</li>
        <li> Visite intervalworld.com para hacerlo todo en un sitio web exclusivo para socios fácil de usar. Busque en el Directorio de complejos turísticos, descubra grandes ofertas de viajes, encuentre valiosos descuentos, compre entradas para parques temáticos con antelación, ¡y mucho más! Y hacer un intercambio o reservar una escapada no podría ser más fácil: ¡apunte, haga clic y prepárese para hacer las maletas!</li>
      </ul>

      <label>*Las ventajas varían según el país de residencia del socio.</label>
    </div>
  </b-card>
</template>

<script>
export default {
	computed: {
    langLocale(){
      return this.$i18n.locale
    }
	},
}
</script>